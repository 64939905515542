import React from 'react'
import "./home.css"
import Data from './Data';

const Home = () => {
  return (
    <section className="home section" id="home">
      <div className="home__container container">
        <div className="home__content">
          <h2 className="home__title">
            Hi, I'm Peter Kim
            <svg className="home__hand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enableBackground="new 0 0 64 64"><g fill="#ffdd67"><path d="m10.3 9.9c-2.2 0-3.9 1.8-3.9 4v29.4h7.8v-29.4c0-2.2-1.7-4-3.9-4"/><path d="m31.3 43.3h8.3v-33.5c0-2.2-1.9-4.1-4.1-4.1-2.3 0-4.1 1.9-4.1 4.2-.1 0-.1 33.4-.1 33.4"/></g><path d="m35.5 5.7c-.3 0-.5 0-.7.1 1.9.3 3.4 2 3.4 4v33.5h1.5v-33.5c-.1-2.3-2-4.1-4.2-4.1" fill="#eba352"/><path d="m14.2 43.3h8.5v-34.2c0-2.4-1.9-4.3-4.3-4.3-2.4 0-4.3 1.9-4.3 4.3l.1 34.2" fill="#ffdd67"/><path d="m18.5 4.8c-.3 0-.5 0-.7.1 2 .4 3.5 2.1 3.5 4.3v21.3l1.5 2.1v-23.5c0-2.4-1.9-4.3-4.3-4.3" fill="#eba352"/><path d="m22.8 40.5h8.5v-34.2c0-2.4-1.9-4.3-4.3-4.3-2.4 0-4.3 1.9-4.3 4.3l.1 34.2" fill="#ffdd67"/><g fill="#eba352"><path d="m27 2c-.3 0-.5 0-.7.1 2 .4 3.5 2.1 3.5 4.3v24.1l1.5 2.1v-26.3c0-2.4-1.9-4.3-4.3-4.3"/><path d="m10.3 9.9c-.3 0-.5 0-.7.1 1.8.4 3.2 2 3.2 3.9v17.5l1.5 2.1v-19.6c-.1-2.2-1.8-4-4-4"/></g><path d="m57.7 29.3c-1.6-3.1-6.6-2.9-11.7 2.7-3.6 3.9-4.3 5.2-7.9 5.1v-4.1c0 0-31.7.1-31.7 2.6 0 0-1.5 11.1 1.6 18.1 4.6 10.4 26.2 12 35.3-4.9 5.3-10 16.1-16.2 14.4-19.5" fill="#ffdd67"/><g fill="#eba352"><path d="m57.7 29.3c-.3-.6-.7-1-1.2-1.4.1.1.2.2.2.4 1.7 3.4-3.4 6.9-7 10.5-2.9 2.9-5.7 5.9-7.1 9.4-6.4 15.3-25 15.3-32.8 8.3 7.1 8 27 8.7 33.8-7.3 1.5-3.5 4.1-6.4 7.1-9.4 3.6-3.7 8.7-7.2 7-10.5"/><path d="m39.1 37.1c-5.9-2.1-18.1 2-16.5 13.7 0-10.9 9.9-13.6 15.3-13.6.7-.1 1.2-.1 1.2-.1"/></g></svg>
          </h2>
          <div className="home__img"></div>
          <Data />
        </div>
      </div>
    </section>
  )
}

export default Home