import React from 'react'

const Info = () => {
  return (
    <div className="about__info">
      <div className="about__box">
        <i className='bx bx-briefcase-alt about__icon'></i>
        <div>
          <h2 className="about__title">8+</h2>
          <span className="about__subtitle">years in the industry from startup to a large corporation</span>
        </div>
      </div>
      <div className="about__box">
        <i className='bx bx-message-alt-add about__icon'></i>
        <div>
          <h2 className="about__title">1000+</h2>
          <span className="about__subtitle">contributions made to JIRA tickets</span>
        </div>
      </div>
      <div className="about__box">
        <i className='bx bx-pen about__icon'></i>
        <div>
          <h2 className="about__title">750+</h2>
          <span className="about__subtitle">Github PRs raised, reviewed and merged</span>
        </div>
      </div>
    </div>
  )
}

export default Info