import React from "react";
import "./skills.css";

/* SVG Icons for Dev Tools */
import { ReactComponent as DockerSvg } from "../../assets/docker.svg";
import { ReactComponent as VsSvg } from "../../assets/vs.svg";
import { ReactComponent as GithubSvg } from "../../assets/github.svg";
import { ReactComponent as JiraSvg } from "../../assets/jira.svg";
import { ReactComponent as LinuxSvg } from "../../assets/linux.svg";

/* SVG Icons for Frontend */
import { ReactComponent as NpmSvg } from "../../assets/npm.svg";
import { ReactComponent as ReactSvg } from "../../assets/reactjs.svg";
import { ReactComponent as TypeScriptSvg } from "../../assets/typescript.svg";
import { ReactComponent as MaterialUiSvg } from "../../assets/material-ui.svg";
import { ReactComponent as Bootstrap5Svg } from "../../assets/bootstrap5.svg";
import { ReactComponent as Html5Svg } from "../../assets/html5.svg";
import { ReactComponent as CssSvg } from "../../assets/css.svg";
import { ReactComponent as SassSvg } from "../../assets/sass.svg";
import { ReactComponent as JavaScriptSvg } from "../../assets/js.svg";
import { ReactComponent as JQuerySvg } from "../../assets/jquery.svg";

/* SVG Icons for Backend */
import { ReactComponent as NodeSvg } from "../../assets/nodejs.svg";
import { ReactComponent as MySqlSvg } from "../../assets/mysql.svg";
import { ReactComponent as RedisSvg } from "../../assets/redis.svg";
import { ReactComponent as RubySvg } from "../../assets/ruby.svg";
import { ReactComponent as RubyOnRailsSvg } from "../../assets/rails.svg";
import { ReactComponent as GraphQLSvg } from "../../assets/graphql.svg";
import { ReactComponent as WebComponentsSvg } from "../../assets/webcomponents.svg";

const Skills = () => {
  return (
    <section className="skills section" id="skills">
      <h2 className="section__title">Skills</h2>
      <span className="section__subtitle">My technical background</span>

      <div className="skills__container container grid">
        <div className="skills__content">
          <div className="skills__box">
            {/* Development Tools */}
            <div className="skills__group">
              <div className="skills__data">
                <DockerSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Docker</h3>
                </div>
              </div>

              <div className="skills__data">
                <VsSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">VS Code</h3>
                </div>
              </div>

              <div className="skills__data">
                <GithubSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Github</h3>
                </div>
              </div>

              <div className="skills__data">
                <JiraSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Jira</h3>
                </div>
              </div>

              <div className="skills__data">
                <LinuxSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Linux</h3>
                </div>
              </div>

              <div className="skills__group-middle-column-split">
                <div className="skills__data">
                  <NpmSvg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">npm</h3>
                    <span className="skills__level">Basic</span>
                  </div>
                </div>

                <div className="skills__data">
                  <ReactSvg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">React</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>

                <div className="skills__data">
                  <TypeScriptSvg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">TypeScript</h3>
                    <span className="skills__level">Basic</span>
                  </div>
                </div>

                <div className="skills__data">
                  <MaterialUiSvg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">Material UI</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>

                <div className="skills__data">
                  <Bootstrap5Svg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">Bootstrap 5</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Frontend Skills */}
            <div className="skills__group skills__group-middle-column">
              <div className="skills__data">
                <NpmSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">npm</h3>
                  <span className="skills__level">Basic</span>
                </div>
              </div>

              <div className="skills__data">
                <ReactSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">React</h3>
                  <span className="skills__level">Basic</span>
                </div>
              </div>

              <div className="skills__data">
                <TypeScriptSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">TypeScript</h3>
                  <span className="skills__level">Basic</span>
                </div>
              </div>

              <div className="skills__data">
                <MaterialUiSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Material UI</h3>
                  <span className="skills__level">Basic</span>
                </div>
              </div>

              <div className="skills__data">
                <Bootstrap5Svg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Bootstrap 5</h3>
                  <span className="skills__level">Intermediate</span>
                </div>
              </div>

              <div className="skills__data">
                <JavaScriptSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">JavaScript</h3>
                  <span className="skills__level">Intermediate</span>
                </div>
              </div>

              <div className="skills__data">
                <JQuerySvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">jQuery</h3>
                  <span className="skills__level">Intermediate</span>
                </div>
              </div>

              <div className="skills__data">
                <Html5Svg className="skills__icon" />
                <div>
                  <h3 className="skills__name">HTML 5</h3>
                  <span className="skills__level">Advanced</span>
                </div>
              </div>

              <div className="skills__data">
                <CssSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">CSS</h3>
                  <span className="skills__level">Advanced</span>
                </div>
              </div>

              <div className="skills__data">
                <SassSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Sass</h3>
                  <span className="skills__level">Advanced</span>
                </div>
              </div>
            </div>

            {/* Backend Skills */}
            <div className="skills__group">
              <div className="skills__group-middle-column-split">
                <div className="skills__data">
                  <JavaScriptSvg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">JavaScript</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
                </div>

                <div className="skills__data">
                  <JQuerySvg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">jQuery</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
                </div>

                <div className="skills__data">
                  <Html5Svg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">HTML 5</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>

                <div className="skills__data">
                  <CssSvg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">CSS</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>

                <div className="skills__data">
                  <SassSvg className="skills__icon" />
                  <div>
                    <h3 className="skills__name">Sass</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>
              </div>
              <div className="skills__data">
                <NodeSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Node</h3>
                  <span className="skills__level">Basic</span>
                </div>
              </div>

              <div className="skills__data">
                <RedisSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Redis</h3>
                  <span className="skills__level">Intermediate</span>
                </div>
              </div>

              <div className="skills__data">
                <MySqlSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">MySQL</h3>
                  <span className="skills__level">Intermediate</span>
                </div>
              </div>

              <div className="skills__data">
                <RubySvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Ruby</h3>
                  <span className="skills__level">Advanced</span>
                </div>
              </div>

              <div className="skills__data">
                <RubyOnRailsSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Ruby on Rails</h3>
                  <span className="skills__level">Advanced</span>
                </div>
              </div>

              <div className="skills__data">
                <GraphQLSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">GraphQL</h3>
                  <span className="skills__level">Intermediate</span>
                </div>
              </div>

              <div className="skills__data">
                <WebComponentsSvg className="skills__icon" />
                <div>
                  <h3 className="skills__name">Web Components</h3>
                  <span className="skills__level">Intermediate</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
