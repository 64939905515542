import React from "react";
import "./qualifications.css";

const Qualifications = () => {
  return (
    <section className="qualifications section" id="qualifications">
      <h2 className="section__title">Qualifications</h2>
      <span className="section__subtitle">My personal journey</span>

      <div className="qualifications__container container">
        <div className="qualifications__sections">
          <div className="qualifications__content">
            <div className="qualifications__data qualifications__data-left">
              <div>
                <h3 className="qualifications__title">
                  Platform & Integrations Engineer
                </h3>
                <span className="qualifications__subtitle">RealScout</span>
                <div className="qualifications__calendar">
                  <div className="qualifications__calendar">
                    <i className="uil uil-calendar-alt"></i> 2023 - Present
                  </div>
                </div>
              </div>
              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>
            </div>
            <div className="qualifications__data qualifications__data-right">
              <div></div>
              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>
              <div>
                <h3 className="qualifications__title">Software Engineer</h3>
                <span className="qualifications__subtitle">
                  Diligent Corporation
                </span>
                <div className="qualifications__calendar">
                  <div className="qualifications__calendar">
                    <i className="uil uil-calendar-alt"></i> 2016 - Present
                  </div>
                </div>
              </div>
            </div>

            <div className="qualifications__data qualifications__data-left">
              <div>
                <h3 className="qualifications__title">Full Stack Developer</h3>
                <span className="qualifications__subtitle">
                  BoardEffect, LLC
                </span>
                <div className="qualifications__calendar">
                  <div className="qualifications__calendar">
                    <i className="uil uil-calendar-alt"></i> 2015 - 2016
                  </div>
                </div>
              </div>
              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>
            </div>

            <div className="qualifications__data qualifications__data-right">
              <div></div>
              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>
              <div>
                <h3 className="qualifications__title">
                  Junior Software Engineer
                </h3>
                <span className="qualifications__subtitle">
                  SparkNET Technologies, LLC
                </span>
                <div className="qualifications__calendar">
                  <div className="qualifications__calendar">
                    <i className="uil uil-calendar-alt"></i> 2014 - 2015
                  </div>
                </div>
              </div>
            </div>

            <div className="qualifications__data qualifications__data-left">
              <div>
                <h3 className="qualifications__title">Lehigh University</h3>
                <span className="qualifications__subtitle">
                  Computer Science
                </span>
                <div className="qualifications__calendar">
                  <div className="qualifications__calendar">
                    <i className="uil uil-calendar-alt"></i> 2009 - 2013
                  </div>
                </div>
              </div>

              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualifications;
